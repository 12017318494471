import * as THREE from 'three'
import create from 'zustand'
import loggerMiddleware from '../utils/loggerMiddleware'
import { config} from '@react-spring/three';
import { createRef } from 'react'
import { shuffleArr } from "../utils"

let vec3 = new THREE.Vector3(0, 0, 1) 

const log = loggerMiddleware('Scene')

const colorArr =[
  '#e6613e', //red
  '#4295CF', //blue
  '#17AF88', // green
  '#8378A3', //purple
  "#ffaa51", //orange
  '#65ca64', //"#8ac355", //green - strongest
 // '#d79ac0', //pink
  '#e6613e', //red
]

const imgArr =[
  'shame-cover-wrap.jpg',
  'mean-cover-wrap.jpg',
  'embarrassed-cover-wrap.jpg',
  'sorry-cover-wrap.jpg',
  'afraid-cover-wrap.jpg',
  'lonely-cover-wrap.jpg',
  'jealous-cover-wrap.jpg',
  'sad-cover-wrap.jpg',
  'worried-cover-wrap.jpg',
  'mad-cover-wrap.jpg',
  

]

imgArr.reverse();

const audioArr = [
  "C'mon, let's have fun!",
  "Check out our Book Song Challenge 2022 winners!",
 // "Play the Way Past Fun card games!",
  "Follow along and Draw Your Own Dogs!",
  "Abigail's tips on how to get past your Way Past Mad.",
  "Check out these links to learn Way Past more.",
];

const messageArr = [
  "C'mon, let's have fun!",
  //"3rd Annual Book Song Challenge from June 6-8th!",
 // "4th Annual Book Song Challenge from May 1st - 4th!",
// "Double Book Launch Party!",

   //
 // "See our Book Song Challenge Winners!",
  //"Check out our Book Song Challenge 2022 winners!",
  "6th Annual Book Song Challenge from May 5th - 7th!",
  "Way Past Shame Book Launch Party May 10th!",
 // "Play the Star Shades Interactive Game!",
  //"More Way Past Mean Activities!",
  //"Way Past Mean Book Launch Party!",
  "Play the Mixed Emotions Interactive Game!",
 // "Way More Fun!",
 // "Follow along and Draw Your Own Dogs!",
 
 // "Play the Way Past Fun card games!",
 //"Check out our Book Song Challenge 2020 winners!",
// ----> was: "Watch one of our favorite videos!",
 // "Abigail's tips on how to get past your Way Past Mad.",
 "Enjoy the Way Past Books Series!",
 "Other books by Hallee Adelman.",
  "Check out these links to learn Way Past more.",
];

const YouTubeVideoArr = [
 
  ['Vwj5iRifCWM',`Check out the Way Past Worried Drive Thru Book Launch`],
  ['NPPIDzNf2es',`Watch Abigail's Way Past Worried Book Review`],
  ['p4CAAoa3xm4',`Watch London's Way Past Worried Book Review`],
  ['VNmM_yI5a4o',`Watch Sage's Way Past Worried Book Review`],
  ['nKMqPRh8k5I',`Watch Saul's Way Past Worried Book Review`],
  ['CHV8ni_qJl8',`Learn to Make Tie Dye Masks with Hara and Jordyn`],
  ['w2h3t-NqHto',`Do the Way Past Mad Dance with Jordyn and Hara`],
  ['y4Wgahb0ysA',`Sing a Song Like the 76ers Sixth Man`],
  ['OZU1nrEB9GA',`Check out our Book Song Challenge 2020 winners!`],
  //['nEBSj-ilp5Y', `Book Song Challenge 2020 Announcement`],
  ['Rpvj-3my8jM',`Stuck at Home #6 Avery Quiet Ship`],
  ['GffDA4XJQ-k',`Story Series: Penny Wish`],
  ['On6QGqwK7Vo',`Stuck at Home #5 Chloe & Hara Driveway Birthday`],
  ['ZXXAS_onTgY',`Stuck at Home #4: Sage Makes MadCakes`],
  ['aL8A-IsaL6M',`Stuck at Home #3: Card Games`],
  ['T0A32oBnL1U',`Stuck at Home #2 with Kevin & Boba`],
  ['4KP6Q588tRg',`Stuck at Home #1 with Jordyn & Hara`],
  ['iqEkNAunt9o',`Saul's Tips for How to Get Past Your Mad`],
  ['SPz4n5Eson4',`Saul's Book Review`],
  ['ObVDR_neAc8',`Abigail Activity`],
  ['1qr9WwxR72k',`Abigail's tips on how to get past your Way Past Mad.`],
];
const ytShuffleArr = shuffleArr(YouTubeVideoArr);






const useStore = create(log((set, get) => {
  return {
    mode: 'scroll',
    pageState: 'default',
    bookArr: imgArr,
    bookTotal: imgArr.length,
    allColors: colorArr,
    sections:7,
    isOverlayVisible: false,
    isCategoryOverlayVisible: false,
    pages: 7,
    zoom: 1,
    panel: 0,
    bkgd: 'white',
    top: createRef(),
    scrollPos: 0,
    panelActive: 0,
    bookState: 'float',
    bookOtherState: 'stack',
    topic: messageArr[0],
    youTubeArr: ytShuffleArr,
    bkgdColor: colorArr[0],
    character: 0,
    talk:0,
    shuffled: false,
    showBug: false,
    cursorActive: true,
    contentLayout: 'spiral',
    bookIndex:  9, /// set default carousel state (slides)
    bookOtherIndex: 1,
    loaded: true,
    defaultHeight: null,
    fullMenuVisible: false,
    filterGrid: null,
    modalState: false,
    featured: null,
    messages: messageArr,
    events:null,
    domRef: null,
    scrollRef: null,
    wordBalloonVisible: true,
    gridHeight: '100',
    //gridColumns: 6,
   // gridColSpacing: 10,
   // gridRowSpacing: 10,

  


    scene: 'intro',

    bloomStrength: .2,
    bloomRadius: 2.0,
    bloomThreshold: .3,
    saturationLevel: -0.0 ,
    opacityLevel: 0.4 ,
    greyLevel: .4 ,
    purpleLevel:  0.7 ,
    rpct: 0.1,
    gpct: 0.4,
    bpct: 0.4,

    zoomSpeed: { mass: 40, friction: 80, tension: 50 },
    zoomTarget: vec3,
    zoomLevel: 14,
    isInteracting: false,
    flash: false,
    isPlayingVideo: false,
    colorize: false,
    layoutSpeed: config.default,
    layout: "spread",
    gridAtlasData: null,
    

    actions: {
      setScene(view) {
        set(state => ({ scene: view, transitioning: false }))
      },
      setPageState(path) {
        set(state => ({ pageState: path }))
      },
      setMode(view) {
        set(state => ({ mode: view }))
      },
      setScrollPos(num) {
        set(state => ({ scrollPos: num }))
      },
      setPanelActive(num) {
        set(state => ({ panelActive: num }))
      },
      setBookState(val) {
        set(state => ({ bookState: val }))
      },
      setBookOtherState(val) {
        set(state => ({ bookOtherState: val }))
      },
      setTopic(val) {
        set(state => ({ topic: val }))
      },
      setHomeMessage(val) {
        if (get().mode !== 'grid'){
          set(state => ({ topic: val }))
        }
      },
      setBkgdColor(num) {
        set(state => ({ bkgdColor: colorArr[num] }))
      },
      setCharacter(num) {
        set(state => ({ character: num }))
      },
      setTalk(num) {
        set(state => ({ talk: num }))
      },
      setShuffled(boo) {
        set(state => ({ shuffled: boo }))
      },
      setIsOverlayVisible(boo) {
        set(state => ({ isOverlayVisible: boo }))
      },
      setIsCategoryOverlayVisible(boo) {
        set(state => ({ isCategoryOverlayVisible: boo }))
      },
      setShowBug(boo) {
        set(state => ({ showBug: boo }))
      },
      setCursorActive(boo) {
        set(state => ({ cursorActive: boo }))
      },
      setContentLayout(val) {
        set(state => ({ contentLayout: val }))
      },
      setBookIndex(num) {
        set(state => ({ bookIndex: num }))
      },
      setBookOtherIndex(num) {
        set(state => ({ bookOtherIndex: num }))
      },
      setGridHeight(num) {
        set(state => ({ gridHeight: num }))
      },
      setLoaded(boo) {
        set(state => ({ loaded: boo }))
      },
      setDefaultHeight(num) {
        set(state => ({ defaultHeight: num }))
      },
      setFullMenuVisible(boo) {
        set(state => ({ fullMenuVisible: boo }))
      },
      setFilterGrid(val) {
        set(state => ({ filterGrid: val }))
      },
      setModalState(boo) {
        set(state => ({ modalState: boo }))
      },
      setFeatured(val) {
        set(state => ({ featured: val }))
      },
      setEvents(val) {
        set(state => ({ events: val }))
      },
      setDomRef(val) {
        set(state => ({ domRef: val }))
      },
      setScrollRef(val) {
        set(state => ({ scrollRef: val }))
      },
      setWordBalloonVisible(boo) {
        set(state => ({ wordBalloonVisible: boo }))
      },


      setZoomTarget(xp,yp,zp,dist,speed, reset = false) {
        const camCenter = (dist>0) ? new THREE.Vector3(xp, yp, zp).add(new THREE.Vector3(0, 0, dist)) : new THREE.Vector3(xp, yp, zp);
        set(state => ({ 
          zoomTarget: camCenter, 
          zoomSpeed: speed,
          isInteracting: reset,
        }))
      },
      setBloom(strength, radius, threshold) {
        set(state => ({ 
          bloomStrength: strength,
          bloomRadius: radius,
          bloomThreshold: threshold,
        }))
      },
      setPct(red, blue, green) {
        set(state => ({ 
          rpct: red,
          bpct: blue,
          gpct: green,
        }))
      },
      setSaturation(num) {
        set(state => ({ saturationLevel: num}))
      },
      setFlash(boo) {
        set(state => ({ flash: boo}))
      },
      setColorize(boo) {
        set(state => ({ colorize: boo}))
      },
      setIsPlayingVideo(val){
        set(state => ({ 
          isPlayingVideo: val,
        }))
      },
      setLayout(val, speed) {
        set(state => ({ 
          layout: val, 
          layoutSpeed: speed,
        }))
      },
      setZoomLevel(val, speed) {
        set(state => ({ 
          zoomLevel: val,
          zoomSpeed: speed
        }))
      },
      setGridAtlasData(obj) {
        set(state => ({gridAtlasData: obj}))
      },
      setTransitioning(boo) {
        set(() => ({ transitioning: boo }))
      },
      setTransitionComplete(resolver) {
        set(() => ({ transitionComplete: resolver }))
      },
      registerTeardownTask(key, prom) {
        const tasks = get().teardownTasks
        tasks[key] = prom
        set(() => ({ teardownTasks: tasks }))
      },
      setTeardownTasks(obj) {
        set(() => ({ teardownTasks: obj }))
      },
    }
  }
}))

export default useStore